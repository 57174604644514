import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import SetupForm from './SetupForm';
import s from "./PaymentProfile.css";
import Loader from '../../../components/Common/Loader/Loader';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';


function PaymentProfile({adminEmail, store}) {
    const [clientSecret, setClientSecret] = useState("");
    const [stripePromise, setStripePromise] = useState("");
    const [inProc, setInProc] = useState(false);
    const [heading, setHeading] = useState("Setup Payment Profile");
    const [showform, setShowform] = useState("showform");
    const [paymentMethodSetup,setPaymentMethodSetup] = useState(null);


    const fetchJobs = async () => {
      const paydata = await fetch("/get-payment-method-setup", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ items:  adminEmail }),
      });
      const paymentMethod = await paydata.json();
      const paymentMethodSetup = await paymentMethod.userPaymentMethod.paymentMethodSetup;
      setPaymentMethodSetup(paymentMethodSetup);      

      if(paymentMethodSetup === 'active'){
        setHeading("Payment method is already setup!");
        setShowform("donotshowform");
      }    
  
      const intentClientSecret = await new URLSearchParams(window.location.search).get(
        'setup_intent_client_secret'
      );

      const resdata = await fetch("/get-stripepublisablekey", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
      const stripekeydata = await resdata.json();
      const stripePromise = loadStripe(stripekeydata.stripePublishableKey);
      setStripePromise(stripePromise);      
      setClientSecret(intentClientSecret);

      if((!intentClientSecret)&&(paymentMethodSetup !== 'active')){
        try {
          const res = await fetch("/create-setup-intent", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ items:  adminEmail }),
            });
          const data = await res.json();
          setClientSecret(data.clientSecret); 
        } catch (error) {
          console.log('Error fetching data', error);
          return false;
        } 
      }

      return true;
    };

    const resetPaymentSetup = async () => {
      const res = await fetch("/reset-payment-method-setup", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ items:  adminEmail }),
      });
      if(res){
        console.log('Working load');
        window.location.reload();
      }
    }

    useEffect(() => {
      if(!inProc){
        fetchJobs().then( (result) => {
          setInProc(result);
        });
      }
    }, []);


    const appearance = {
      theme: 'stripe',
    };
    const options = {
      clientSecret,
      appearance,
    };

    return (
      <Loader type={"page"} >
        <div className={s.root}>
            <div className={s.container}>
                <div className={s.heading}>
                  {heading}
                  <br/>
                  { paymentMethodSetup === 'active' ? <button type="button" onClick={resetPaymentSetup} >Delete existing Payment Setup</button> : ''}
                </div>
                <div className={s.paddingRoutesSection}>
                  <div className={showform}>
                    {clientSecret && (
                      <Elements options={options} stripe={stripePromise}>
                        <SetupForm adminEmail={adminEmail} store={store} />
                      </Elements>
                    )}
                  </div>     
                </div>
            </div>
        </div>
      </Loader>
    );
}

export default compose(
  withStyles(s),
)(PaymentProfile);