import React from 'react';
import Layout from '../../components/Layout';
import NotFound from '../not-found/NotFound';
import Page from './Page';
import { getContent } from '../../actions/siteadmin/ContentPageAction'

async function action({ store, params }) {
    let title;
    let pageUrl = params.pageUrl;
    await store.dispatch(getContent(pageUrl));
    let getContentPage = store.getState().page;
    if (getContentPage && getContentPage.data) {
        return {
            title: getContentPage.data.metaTitle,
            component: (
                <Layout><Page data={getContentPage.data} /></Layout>
            )
        }
    } else {
        title = "Not Found"
        return {
            title,
            component: <Layout><NotFound title={title} /></Layout>,
            status: 404,
        };
    }


}

export default action;