import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

//Styles
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './CancelBookingModal.css';
import cx from 'classnames';


import { injectIntl } from 'react-intl';
import messages from '../../../locale/messages';

import CancelBookingForm from './CancelBookingForm';
import { closeCancelBookingModal, setUserType } from '../../../actions/siteadmin/modalActions';

class CancelBookingModal extends Component {

  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
  }

  async handleSelect(key) {
    const { setUserType } = this.props;
    setUserType(key == '1' ? 1 : 2)
  }


  render() {
    const { closeCancelBookingModal, cancelBookingModal, userType } = this.props;
    const { formatMessage } = this.props.intl;

    return (
      <div>
        <Modal show={cancelBookingModal} onHide={closeCancelBookingModal} className={cx('wooberlyModal', 'cancelBookingTabs')}>
          <Modal.Body className={s.logInModalBody}>
            <div className={cx(s.container, 'closeBookingModalTabs')}>
              <Tabs id="controlled-tab-example" onSelect={(e) => this.handleSelect(e)} activeKey={userType}>
                <Tab eventKey="1" title={formatMessage(messages.canceledByRider)} >
                  {userType == 1 && <CancelBookingForm userType={userType} closeCancelBookingModal={closeCancelBookingModal} />}
                </Tab>
                <Tab eventKey="2" title={formatMessage(messages.canceledByDriver)}>
                  {userType == 2 && <CancelBookingForm userType={userType} closeCancelBookingModal={closeCancelBookingModal} />}
                </Tab>
              </Tabs>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapState = (state) => ({
  cancelBookingModal: state.adminModalStatus.cancelBookingModal,
  userType: state.adminModalStatus.userType,
});

const mapDispatch = {
  closeCancelBookingModal,
  setUserType
};

export default injectIntl(withStyles(s)(connect(mapState, mapDispatch)(CancelBookingModal)));