import messages from '../../../../locale/messages';

const validate = values => {
	const errors = {};

	if (!values.locationId) {
		errors.locationId = messages.required;
	}

	if (!values.categoryId) {
		errors.categoryId = messages.required;
	}

	if (!values.basePrice && values.basePrice !== 0) {
		errors.basePrice = messages.required;
	} else if (isNaN(values.basePrice)) {
		errors.basePrice = messages.floatError;
	}

	if (!values.unitPrice && values.unitPrice !== 0) {
		errors.unitPrice = messages.required;
	} else if (isNaN(values.unitPrice)) {
		errors.unitPrice = messages.floatError;
	}

	if (!values.minutePrice && values.minutePrice !== 0) {
		errors.minutePrice = messages.required;
	} else if (isNaN(values.minutePrice)) {
		errors.minutePrice = messages.floatError;
	}

	if (!values.riderFeeValue && values.minutePrice !== 0) {
		errors.riderFeeValue = messages.required;
	} else if (!/^[0-9]+$/.test(values.riderFeeValue)) {
		errors.riderFeeValue = messages.invalid
	} else if (values.riderFeeValue && (parseInt(values.riderFeeValue, 10) < 0 || parseInt(values.riderFeeValue, 10) > 99)) {
		errors.riderFeeValue = messages.percentError;
	}

	if (!values.driverFeeValue && values.minutePrice !== 0) {
		errors.driverFeeValue = messages.required;
	} else if (!/^[0-9]+$/.test(values.driverFeeValue)) {
		errors.driverFeeValue = messages.invalid
	} else if (values.driverFeeValue && (parseInt(values.driverFeeValue, 10) < 0 || parseInt(values.driverFeeValue, 10) > 99)) {
		errors.driverFeeValue = messages.percentError;
	}

	if (values.baseFare && isNaN(values.baseFare)) {
		errors.baseFare = messages.floatError;
	}

	if (values.surgePricing && values.surgePricing.length > 0) {
		const itemArrayErrors = [];
		values.surgePricing.forEach((item, index) => {
			const itemErrors = {};

			// Item Price
			if (!item.pricingType) {
				itemErrors['pricingType'] = messages.required;
				itemArrayErrors[index] = itemErrors;
			}

			if (item.pricingType == 1) {
				if (!item.datePricingValue) {
					itemErrors['datePricingValue'] = messages.required;
					itemArrayErrors[index] = itemErrors;
				}
			} else {
				if (!item.dayPricingValue) {
					itemErrors['dayPricingValue'] = messages.required;
					itemArrayErrors[index] = itemErrors;
				}
			}

			if (!item.surgeValue) {
				itemErrors['surgeValue'] = messages.required;
				itemArrayErrors[index] = itemErrors;
			} else if (item.surgeValue && item.surgeValue.toString().trim() == '') {
				itemErrors['surgeValue'] = messages.required;
				itemArrayErrors[index] = itemErrors;
			} else if (item.surgeValue && (isNaN(item.surgeValue) || (!/^[0-9\.]+$/.test(item.surgeValue)))) {
				itemErrors['surgeValue'] = messages.invalid;
				itemArrayErrors[index] = itemErrors;
			} else if (item.surgeValue && item.surgeValue < 0.5) {
				itemErrors['surgeValue'] = messages.validateSurge;
				itemArrayErrors[index] = itemErrors;
			}

			if (parseFloat(item.startTime) >= parseFloat(item.endTime)) {
				itemErrors['endTime'] = messages.endTimeValidateMessage;
				itemArrayErrors[index] = itemErrors;
			}
		})

		if (itemArrayErrors.length) {
			errors.surgePricing = itemArrayErrors;
		}

	}

	if (values.waitingPrice && (isNaN(values.waitingPrice || values.waitingPrice.toString().trim() == ''))) {
		errors.waitingPrice = messages.floatError;
	}

	return errors;
};

export default validate