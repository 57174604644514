import messages from '../../../locale/messages';

const validate = values => {
    const errors = {};

    if (!values.homeSectionTitle1) {
        errors.homeSectionTitle1 = messages.required;
    } else if (values.homeSectionTitle1.toString().trim() == "") {
        errors.homeSectionTitle1 = messages.required;
    } else if (values.homeSectionTitle1.length < 2) {
        errors.homeSectionTitle1 = messages.required
    } else if (values.homeSectionTitle1.length > 34) {
        errors.homeSectionTitle1 = messages.exceedLimit
    }

    if (!values.homeSectionButton1) {
        errors.homeSectionButton1 = messages.required;
    } else if (values.homeSectionButton1.toString().trim() == "") {
        errors.homeSectionButton1 = messages.required;
    } else if (values.homeSectionButton1.length < 2) {
        errors.homeSectionButton1 = messages.required
    } else if (values.homeSectionButton1.length > 21) {
        errors.homeSectionButton1 = messages.exceedLimit
    }

    if (!values.homeSectionContent1) {
        errors.homeSectionContent1 = messages.required;
    } else if (values.homeSectionContent1.toString().trim() == "") {
        errors.homeSectionContent1 = messages.required;
    }



    return errors;
};

export default validate;