import {
    GET_CONTENT_SUCCESS
} from '../constants/index'

export default function page(state = {}, action) {
    switch (action.type) {
        case GET_CONTENT_SUCCESS:
            return {
                ...state,
                data: action.payload.pageData,
                staticPageData: action.payload.staticPageData,
            }

        default:
            return {
                ...state
            };
    }
};
