import React, { Component } from 'react'
import withStyles from 'isomorphic-style-loader/withStyles';
import { Table, FormControl, Button } from 'react-bootstrap';
import s from './BookingList.css';
import bt from '../../../components/commonStyle.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import cx from 'classnames';
import messages from '../../../locale/messages';
import CustomPagination from '../../CustomPagination';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import getAllBookings from './getAllBookings.graphql'
import PropTypes from 'prop-types';
import Link from '../../Link/Link';
import CurrencyConverter from '../../CurrencyConverter';
import { connect } from 'react-redux';

import ExportImage from '../../../../public/Icons/export.png';

//Helpers
import { capitalizeFirstLetter } from '../../../helpers/capitalizeFirst'
import debounce from '../../../helpers/debounce';

import Loader from '../../../components/Common/Loader';
import CancelReasonBookingModal from '../CancelBookingModal/CancelBookingModal';
import CancelBookingModal from '../../ManualBooking/CancelBookingModal/CancelBookingModal';

import { openCancelBookingModal, openCancelManualBookingModal } from '../../../actions/siteadmin/modalActions';
import refreshIcon from '../../../../public/Icons/refreshIcon.svg';
import ExportImageDark from '../../../../public/Icons/exportDark.png';

const socketReceivers = [
    'startTrip'
];
export class BookingList extends Component {
    static propTypes = {
        bookingDetails: PropTypes.object,
        bookingType: PropTypes.number,
        manualBooking: PropTypes.bool
    }
    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1
        }
        this.paginationData = this.paginationData.bind(this);
        this.handleKeywordSearch = debounce(this.handleKeywordSearch.bind(this), 250);
        this.handleSocketResponse = this.handleSocketResponse.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.createBookingButton = this.createBookingButton.bind(this)
    }

    componentDidMount() {
        const { socket } = this.props;
        let that = this;
        if (socket) {
            socketReceivers.map((item) => {
                socket.on(`${item}`, (data) => { that.handleSocketResponse(); });
            });
        }
    }

    componentWillUnmount() {
        const { socket, userId } = this.props;

        try {
            if (socket && socketReceivers) {
                socketReceivers.map((item) => {
                    socket.removeListener(`${item}`);
                });
            }
            clearTimeout(this.buttonLoaderTimeout);
        } catch (error) {
            // console.log('error', error);
        }
    }

    handleSocketResponse() {
        this.paginationData(this.state.currentPage);
    }


    handleKeywordSearch(searchList) {
        const { bookingDetails: { refetch } } = this.props
        let variables = {
            currentPage: 1,
            searchList
        }
        this.setState(variables)
        refetch(variables)
    }

    paginationData(currentPage) {
        const { bookingDetails: { refetch } } = this.props;
        let variables = { currentPage };
        this.setState({ currentPage });
        refetch(variables);
    }

    createBookingButton = () => (
        <Link to={'/siteadmin/manual-bookings/create'}>
            <div className={cx(bt.noPadding, s.buttonMargin, 'textAlignRightRTL')}>
                <Button className={cx(bt.btnPrimary, s.marginBottom20)}>
                    <FormattedMessage {...messages.addNewLabel} />
                </Button>
            </div>
        </Link>
    );

    async confirmtripcompleted(id, riderPayableFare, adminId, currentPage, currency, e) {
        const { bookingDetails: { refetch } } = this.props;
        let variables = { currentPage };
        this.setState({ currentPage });
        e.disabled = true;

        try {
            const res = await fetch("/confirm-trip-completed", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ items: {id: id, riderPayableFare: riderPayableFare, adminId: adminId, currency: currency} }),
              });
            const data = await res.json();
            console.log('payment success 2');
            console.log(data);
            refetch(variables);
        } catch (error) {
            console.log('Error fetching data', error);
            return false;
          } 
    };


    handleCancel(data) {
        const { openCancelBookingModal, openCancelManualBookingModal } = this.props;
        if (['scheduled'].includes(data.tripStatus)) openCancelManualBookingModal({ booking: data })
        else openCancelBookingModal(data)
    }



    render() {
        const { bookingDetails, bookingDetails: { getAllBookings, refetch }, bookingType, loading, isManualBooking, openCancelBookingModal, selectedTheme } = this.props;
        const { currentPage, searchList } = this.state;
        const { formatMessage } = this.props.intl;

        let exportType = bookingType === 1 ? 'bookings' : 'schedule-bookings';
        if (isManualBooking) exportType = 'manual-bookings'

        return (
            <div className={cx(s.widthInner, 'bgBlackTwo')}>
                <CancelBookingModal />
                <CancelReasonBookingModal />

                <div className={s.exportDisplay}>
                    <div className={cx(s.searchInput, 'searchInputRTL')}>
                        <FormControl type='text' placeholder={formatMessage(messages.searchOnly)} onChange={(e) => this.handleKeywordSearch(e.target && e.target.value)} className={bt.formControlInput} />
                        <div className={s.hoverShow}>
                            <img src={refreshIcon} className={cx(s.refreshImage, 'refreshImageRTL')} onClick={() => this.paginationData(currentPage)} />
                            <div className={cx(s.hoverHide, 'bgBlack', 'hoverHideDark', 'hoverHideRTL')}><FormattedMessage {...messages.refreshText} /></div>
                        </div>
                    </div>

                    <div className={cx(s.exportTextSection, 'textAlignLeftRTL', 'textAlignRightMbRTL')}>
                        {
                            bookingDetails && bookingDetails.getAllBookings && bookingDetails.getAllBookings.bookingData && bookingDetails.getAllBookings.bookingData.length > 0 && <a
                                href={`/export-admin-data?type=${exportType}&keyword=${searchList ? searchList : ''}`}
                                className={cx('pull-right', 'exportTextBookingRTL', s.textDecorationNone, s.exportPosition)}>
                                <span className={cx(s.vtrMiddle, s.exportText, 'exportTextRTL')}><FormattedMessage {...messages.exportDataIntoCSV} /></span>
                                <span>
                                    {
                                        (selectedTheme && selectedTheme === "light") ?
                                            <img src={ExportImage} className={cx(s.exportImg)} /> : <img src={ExportImageDark} className={cx(s.exportImg)} />
                                    }

                                </span>
                            </a>
                        }
                        <div className={cx(s.floatRight, 'floatLeftRTL')}>{isManualBooking && this.createBookingButton()}</div>
                    </div>
                </div>

                <div class="clearfix"></div>
                {
                    loading && <div>
                        <Loader type="circle" />
                    </div>
                }
                {!loading &&
                    <div className={cx(s.tableCss, 'tableCss', 'tableSticky', 'NewResponsiveTable', 'topScrollbarTable')}>
                        <Table className={cx("table", 'topScrollbarTableContent')}>
                            <thead>
                                <tr>
                                    <th scope="col"><FormattedMessage {...messages.id} /></th>
                                    <th scope="col"><FormattedMessage {...messages.riderName} /></th>
                                    <th scope="col"><FormattedMessage {...messages.driverName} /></th>
                                    <th scope="col"><FormattedMessage {...messages.category} /></th>
                                    <th scope="col"><FormattedMessage {...messages.vehicleNumber} /></th>
                                    <th scope="col"><FormattedMessage {...messages.tripStatus} /></th>
                                    <th scope="col"><FormattedMessage {...messages.totalFare} /></th>
                                    <th scope="col"><FormattedMessage {...messages.tollFee} /></th>
                                    <th scope="col"><FormattedMessage {...messages.details} /></th>
                                    {isManualBooking && <th scope="col">Action</th > }
                                    {!isManualBooking && <th scope="col"><FormattedMessage {...messages.chatLabel} /></th>}
                                    {(exportType == 'bookings' || exportType == 'schedule-bookings') && <th scope="col"><FormattedMessage {...messages.action} /></th>}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    bookingDetails && bookingDetails.getAllBookings && bookingDetails.getAllBookings.bookingData && bookingDetails.getAllBookings.bookingData.length > 0 && bookingDetails.getAllBookings.bookingData.map((item, index) => {
                                        return (
                                            <tr key={index}>
                                                <td data-label={formatMessage(messages.id)}>{item.id}</td>
                                                <td data-label={formatMessage(messages.riderName)}>{item && item.riderDetails && capitalizeFirstLetter(item.riderDetails.firstName)}</td>
                                                <td data-label={formatMessage(messages.driverName)}>{item && item.driverDetails && capitalizeFirstLetter(item.driverDetails.firstName)}</td>
                                                <td data-label={formatMessage(messages.category)}>{item && item.categoryDetails && item.categoryDetails.categoryName}</td>
                                                <td data-label={formatMessage(messages.vehicleNumber)}>{item && item.vehicleNumber && item.vehicleNumber.toUpperCase()}</td>
                                                <td data-label={formatMessage(messages.tripStatus)}>{item.tripStatus && messages[item.tripStatus] ? formatMessage(messages[item.tripStatus]) : item.tripStatus}</td>
                                                <td data-label={formatMessage(messages.totalFare)}><CurrencyConverter from={item.currency} amount={item.totalFare} /></td>
                                                <td data-label={formatMessage(messages.tollFee)}>{item.tollFee > 0 ? <CurrencyConverter from={item.currency} amount={item.tollFee} /> : '-'}</td>
                                                <td data-label={formatMessage(messages.details)}>
                                                    <Link to={'/siteadmin/' + (exportType) + '/view/' + item.id}><FormattedMessage {...messages.view} /></Link></td>
                                                {
                                                    !isManualBooking && <td data-label={formatMessage(messages.chatLabel)}>
                                                        {item.messageData && item.messageData.threadItemsCount > 0 && <Link to={'/siteadmin/' + (exportType) + '/chat-message/' + item.id}><FormattedMessage {...messages.view} /></Link>}
                                                    </td>
                                                }
                                                {isManualBooking &&
                                                    <td data-label="action">
                                                    { (item.tripStatus === 'completed') && (item.adminId) && (!item.transactionId)  ? <button type='button' className={cx(bt.btnPrimary, s.marginBottom20)} onClick={(e) => this.confirmtripcompleted(item.id,item.riderPayableFare,item.adminId, currentPage, item.currency, e)}  >Confirm trip completed</button> : '' }
                                                    { (item.tripStatus === 'completed') && (item.adminId) && (item.transactionId)  ? 'Provider Paid' : '' }</td>
                                                }
                                                {(exportType == 'bookings' || exportType == 'schedule-bookings') && <td data-label={formatMessage(messages.action)}>{['approved', 'scheduled', 'locationReached'].includes(item.tripStatus) ? <span onClick={() => this.handleCancel({ ...item, requestBy: "cancelledByRider", currentPage, reason: "other" })} className={cx(s.cancelButton, 'cancelBtn')}>{formatMessage(messages.cancelButton)}</span> : '-'}</td>}
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    bookingDetails && bookingDetails.getAllBookings && bookingDetails.getAllBookings.bookingData && bookingDetails.getAllBookings.bookingData.length == 0 && (
                                        <tr>
                                            <td colspan="12" className={s.noRecords}><FormattedMessage {...messages.noResult} /></td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </div>
                }
                {
                    bookingDetails && bookingDetails.getAllBookings && bookingDetails.getAllBookings.bookingData && bookingDetails.getAllBookings.bookingData.length > 0
                    && <div className={cx(bt.space5, bt.spaceTop5)}>
                        <CustomPagination
                            total={bookingDetails.getAllBookings.count}
                            currentPage={currentPage}
                            defaultCurrent={1}
                            defaultPageSize={10}
                            change={this.paginationData}
                            paginationLabel={formatMessage(messages.bookings)}
                        />
                    </div>
                }
            </div>
        )
    }
}
const mapState = (state) => ({
    loading: state.intl.loading,
    selectedTheme: state.currency.theme
});
const mapDispatch = {
    openCancelBookingModal,
    openCancelManualBookingModal
};
export default compose(injectIntl,
    withStyles(s, bt),
    connect(mapState, mapDispatch),
    graphql(getAllBookings, {
        name: 'bookingDetails',
        options: (props) => ({
            variables: {
                currentPage: 1,
                searchList: '',
                bookingType: props.bookingType,
                isManualBooking: props.isManualBooking
            },
            fetchPolicy: 'network-only'
        }),
    })
)(BookingList);