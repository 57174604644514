import React, { Component } from 'react'
import { connect } from 'react-redux'
import withStyles from 'isomorphic-style-loader/withStyles';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import {
  Button,
  Row,
  FormGroup,
  Col,
  FormControl,
  Container,
  Form,
  InputGroup
} from 'react-bootstrap';
import cx from 'classnames';
import s from './ConfigSettingsForm.css';
import bt from '../../../components/commonStyle.css';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from '../../../locale/messages'
import submit from './submit'
import validate from './validate'
import Loader from '../../Common/Loader/Loader';


export class ConfigSettingsForm extends Component {
  static defaultProps = {
    loading: false
  };


  renderFormControl = ({ input, label, type, meta: { touched, error }, className, maxlength }) => {
    const { formatMessage } = this.props.intl;
    return (
      <div>
        <FormGroup className={s.formGroup}>
          <div>
            <label className={cx(bt.labelText, s.normalFontWeight)} >{label}</label>
          </div>
          <div>
            <FormControl {...input} placeholder={label} type={type} className={bt.formControlInput} maxlength={maxlength} />
            {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
          </div>
        </FormGroup>
      </div>
    );
  }


  render() {
    const { formatMessage } = this.props.intl;
    const { handleSubmit, logo, loading, submitting, appForceUpdate, tripTone, deleteTripTone, tripToneFile } = this.props;
    const waitingTime = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    return (
      <div>
        <div className={s.paddingTop}>
          <Container fluid>
            <form onSubmit={handleSubmit(submit)}>

              <h5 className={cx(s.headingMobile, 'headingMobileRTL')}>{formatMessage(messages.stripeSettings)}</h5>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="stripePublishableKey" type="text" component={this.renderFormControl} label={formatMessage(messages.stripePublishableKey)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="stripeSecretKey" type="text" component={this.renderFormControl} label={formatMessage(messages.stripeSecretKey)} />
                </Col>
              </Row>

              <h5 className={cx(s.headingMobile, 'headingMobileRTL')}>{formatMessage(messages.twilio)}</h5>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="twillioAccountSid" type="text" component={this.renderFormControl} label={formatMessage(messages.twillioAccountSid)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="twillioAuthToken" type="text" component={this.renderFormControl} label={formatMessage(messages.twillioAuthToken)} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="twillioPhone" type="text" component={this.renderFormControl} label={formatMessage(messages.twillioPhone)} />
                </Col>
              </Row>

              <h5 className={cx(s.headingMobile, 'headingMobileRTL')}>{formatMessage(messages.otherSettings)}</h5>
              <Row>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="maxDistance" type="text" component={this.renderFormControl} label={formatMessage(messages.allowableDistaceTitle) + ' (KM)'} />
                </Col>
                <Col xs={12} sm={12} md={12} lg={6} className={bt.space2}>
                  <Field name="pushNotificationKey" type="text" component={this.renderFormControl} label={formatMessage(messages.pushNotificationKey)} />
                </Col>
              </Row>

              <Col lg={12} md={12} sm={12} xs={12} className={cx(bt.textAlignRight, bt.spaceTop3, 'textAlignLeftRTL', 'loadingBtnRTL')}>
                <FormGroup className={s.formGroup}>
                  <div>
                    <Loader
                      type={"button"}
                      label={formatMessage(messages.submitButton)}
                      show={loading}
                      buttonType={'submit'}
                      className={cx(bt.btnPrimary)}
                      disabled={submitting || loading}
                      isSuffix={true}
                    />
                  </div>
                </FormGroup>
              </Col>

            </form>
          </Container>
        </div>
      </div>
    )
  }
}

ConfigSettingsForm = reduxForm({
  form: 'ConfigSettingsForm',
  onSubmit: submit,
  validate,
})(ConfigSettingsForm);

const selector = formValueSelector('ConfigSettingsForm')

const mapState = (state) => ({
  loading: state.loader.ConfigSettings,
})
const mapDispatch = {
}
export default injectIntl(withStyles(s, bt)(connect(mapState, mapDispatch)(ConfigSettingsForm)));
