import { updateConfigSettings } from '../../../actions/siteadmin/updateMobileSettings'

async function submit(values, dispatch) {

    await dispatch(
        updateConfigSettings(
            values
        )
    )
}

export default submit;