import messages from '../../../../locale/messages';

const validate = (values) => {
    let errors = {};

    if (values.reason && values.reason.toString().trim() === 'other') {
        if (!values.otherReason) {
            errors.otherReason = messages.required;
        } else if (values.otherReason.trim() == "") {
            errors.otherReason = messages.required;
        }
    }

    return errors;
};

export default validate;