import React from 'react';
import Layout from '../../../components/Layout/Layout';
import Page from '../../../components/Page/Page';
import Driver from './Driver'
import { getStaticContent } from '../../../actions/siteadmin/ContentPageAction';

async function action({ locale, store }) {
    const dataResult = await new Promise((resolve) => {
        require.ensure([], (require) => {
            try {
                resolve(require(`./driver.${locale}.md`));
            } catch (e) {
                resolve(require('./driver.md'));
            }
        }, 'driver');
    });

    await store.dispatch(getStaticContent(3));
    let getContentPage = store.getState().page;
    if (getContentPage && getContentPage.staticPageData) {
        return {
            title: getContentPage.staticPageData.metaTitle,
            description: getContentPage.staticPageData.metaDescription,
            component: <Layout><Driver data={getContentPage.staticPageData} /></Layout>,
        };

    } else {
        return {
            title: dataResult.title,
            component: <Layout><Page {...dataResult} /></Layout>,
        };
    }
}

export default action