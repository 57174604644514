import React from "react";
import s from './ManualBooking.css'
import Loader from '../../../components/Common/Loader/Loader';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';

function DisabledManualBooking() {
    return (
        <Loader type={"page"} >
          <div className={s.root}>
              <div className={s.container}>
                  <div className={s.heading}>
                  You have to setup payment profile first to create Manual Booking
                  </div>
              </div>
          </div>
        </Loader>
    );
}

export default compose(
  withStyles(s),
)(DisabledManualBooking);