import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './HomeSection.css';
import bt from '../../../components/commonStyle.css';
import cx from 'classnames';
import { Container, Button } from 'react-bootstrap';

import { connect } from 'react-redux';
import Link from '../../Link';
import HomeContext from '../../../routes/context/homeContext';
import { api, homepageUploadDir } from '../../../config';

//Image
import arrow from '../../../../public/Icons/getStartedArrow.svg';
import arrowDark from '../../../../public/Icons/getStartedArrowDark.svg';

class HomeSection extends React.Component {
  render() {
    const homepage = this.context;
    const apiEndpoint = api && api.apiEndpoint;
    const { selectedTheme } = this.props;
    return (
      <div>
        <div
          className={cx(s.carBg, 'd-none  d-xl-block', 'carBgDark')}
          style={{
            backgroundImage: `url(${apiEndpoint}${homepageUploadDir}${homepage.homeSectionImage6}`,
          }}
        >
          <Container>
            <div className={s.homeInner}>
              <div className={cx(s.innerWidth, 'innerWidthRTL')}>
                <div className={cx(s.firstContent, 'textWhite')}>{homepage.homeSectionTitle1}</div>
                <div className={cx(s.descriptionText, 'textWhite')}>
                  {homepage.homeSectionContent1}
                </div>
                <Link
                  className={cx(bt.btnPrimary, s.getButton, 'btnPrimaryDark')}
                  to={'#rider-section'}
                >
                  <span>{homepage.homeSectionButton1}</span>
                  {
                    (selectedTheme && selectedTheme === "light") ?
                      <img src={arrow} className={cx(s.arrowImg, 'homeArrowImgRTL')} /> : <img src={arrowDark} className={cx(s.arrowImg, 'homeArrowImgRTL')} />
                  }


                </Link>
              </div>
            </div>
          </Container>
        </div>
        <div
          className={cx(s.tabView, 'd-none d-sm-block d-md-block  d-xl-none')}
        >
          <img src={apiEndpoint + homepageUploadDir + homepage.homeSectionImage7} fluid={'true'} className={s.imgWidth} />
          <div className={cx(s.firstContent, 'firstContentRTL', 'textWhiteMob')}>
            <div>{homepage.homeSectionTitle1}</div>
            <div className={cx(s.descriptionText, 'textWhiteMob')}>
              {homepage.homeSectionContent1}
            </div>
            <Link
              className={cx(bt.btnPrimary, s.getButton, 'btnPrimaryDark')}
              to={'#rider-section'}
            >
              {homepage.homeSectionButton1}
              <img src={arrow} className={cx(s.arrowImg, 'homeArrowImgRTL')} />
            </Link>
          </div>
        </div>
        <div className={cx(s.tabView, 'd-block d-sm-none')}>
          <img src={apiEndpoint + homepageUploadDir + homepage.homeSectionImage8} fluid={'true'} className={s.imgWidth} />
          <div className={cx(s.firstContent, 'firstContentRTL', 'textWhiteMob')}>
            <div>{homepage.homeSectionTitle1}</div>
            <div className={cx(s.descriptionText, 'textWhiteMob')}>
              {homepage.homeSectionContent1}
            </div>
            <Link
              className={cx(bt.btnPrimary, s.getButton, 'btnPrimaryDark')}
              to={'#rider-section'}
            >
              {homepage.homeSectionButton1}
              {
                (selectedTheme && selectedTheme === "light") ?
                  <img src={arrow} className={cx(s.arrowImg, 'homeArrowImgRTL')} /> : <img src={arrowDark} className={cx(s.arrowImg, 'homeArrowImgRTL')} />
              }
            </Link>
          </div>
        </div>
      </div>

    );
  }
}

HomeSection.contextType = HomeContext;

const mapState = (state) => ({
  selectedTheme: state.currency.theme
});

export default withStyles(s, bt)(connect(mapState)(HomeSection));
