import React, {useState} from 'react';
import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import {siteUrl} from '../../../config';
import PaymentStatus from './PaymentStatus';
import s from "./PaymentProfile.css";

const SetupForm = ({adminEmail,store}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();


    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }

    const {error} = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: siteUrl+'/siteadmin/payment-profile',
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };


  return (
      <form onSubmit={handleSubmit} >
        <PaymentElement  />
        <button className={s.button} disabled={!stripe}>Submit</button>
        {/* Show error message to your customers */}
        {errorMessage && <div>{errorMessage}</div>}
        <div className={s.paymentstatus} ><PaymentStatus adminEmail={adminEmail} store={store} /></div>
      </form>
  )
};

export default SetupForm;