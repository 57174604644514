import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './SignupGrid.css';
import bt from '../../../components/commonStyle.css';
import cx from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';
import HomeContext from '../../../routes/context/homeContext'
import { api, homepageUploadDir } from '../../../config'


class SignupGrid extends React.Component {
  render() {
    const homepage = this.context
    const apiEndpoint = api && api.apiEndpoint
    const { className } = this.props;
    return (
      <div id="driver-section">
        <Container>
          <div className={cx(s.safetyContainer, s.gridBottom, s.imgSectionHeight, className, 'SignupGridContainerRTL', 'bgBlackTwo')}>
          <Row className={s.displayFlex}>
            <Col xl={5} md={5} sm={5} xs={12}>
              <div className={cx(s.signUp, s.textCenter)}>
                <img src={api.apiEndpoint + homepageUploadDir + homepage.signupGridImage3} className={s.phoneImg} />
              </div>
            </Col>
            <Col xl={7} md={7} sm={7} xs={12} className={cx(bt.spaceTop6)}>
              <div className={cx(s.signUp, bt.space5)}>
                <h3>
                  {homepage.signupGridTitle1}
                </h3>
                <p>
                  {homepage.signupGridContent1}
                </p>
              </div>
              <div className={cx(bt.paddingTop5, s.responsiveNoPadding)}>
                <div className={cx(s.displayInlineBlock, s.paddingRight, 'appRightRTL')}>
                <a href={homepage.signupGridLink2} target="_blank" >
                    <img src={api.apiEndpoint + homepageUploadDir + homepage.signupGridImage2} className={s.appImg} />
                  </a>
                </div>
                <div className={cx(s.displayInlineBlock, s.paddingLeft, 'paddingLeftRTL')}>
                <a href={homepage.signupGridLink1} target="_blank" >
                    <img src={api.apiEndpoint + homepageUploadDir + homepage.signupGridImage1} className={s.appImg} />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          </div>
        </Container>
      </div>
    );
  }
}
SignupGrid.contextType = HomeContext
export default withStyles(s, bt)(SignupGrid);
