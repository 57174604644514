import React from 'react';
import AdminLayout from '../../../components/Layout/AdminLayout';
import Tracking from './Tracking';
import messages from '../../../locale/messages';
import { getMapViewData } from '../../../actions/siteadmin/Tracking/getMapViewData';
import { restrictUrls } from '../../../helpers/adminPrivileges';

async function action({ store, intl }) {
  // const title =  'Manage Admin Roles'
  const title = intl.formatMessage(messages.tracking);
  let adminPrivileges = store.getState().adminPrevileges.privileges && store.getState().adminPrevileges.privileges.privileges;

  let isAdminAuthenticated = store.getState().runtime.isAdminAuthenticated;

  if (!isAdminAuthenticated) {
    return { redirect: '/login' }
  }

  if (!restrictUrls('/siteadmin/tracking', adminPrivileges)) {
    return { redirect: '/siteadmin' };
  }

  await store.dispatch(getMapViewData("1", "today"));

  return {
    title,
    component: (
      <AdminLayout><Tracking /></AdminLayout>
    )
  }
}

export default action;
