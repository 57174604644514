import { toastr } from 'react-redux-toastr';
import updateHomepageSettingsAbout from '../../../actions/siteadmin/updateHomepageSettingsAbout'

async function submit(values, dispatch) {
    if (!values.aboutGridImage1 || !values.aboutGridImage2 || !values.aboutGridImage3 || !values.aboutGridImage4) {
        toastr.error('Error!', "Feature banner image is required.");
        return;
    }

    await dispatch(updateHomepageSettingsAbout(values))
}

export default submit;