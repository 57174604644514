import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './FooterImageSection.css';
import bt from '../../../components/commonStyle.css';
import cx from 'classnames';
import { Container } from 'react-bootstrap';
import HomeContext from '../../../routes/context/homeContext';
import { api, homepageUploadDir } from '../../../config';
class FooterImageSection extends React.Component {
  render() {
    const homepage = this.context;
    const apiEndpoint = api && api.apiEndpoint;
    return (
      <Container>
        <div
          className={cx(s.footerSectionBg)}
          style={{
            backgroundImage: `url(${apiEndpoint}${homepageUploadDir}${homepage.footerLogo1})`,
          }}
        >
        </div>
      </Container>
    );
  }
}

FooterImageSection.contextType = HomeContext;

export default withStyles(s, bt)(FooterImageSection);
