exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1xcpk {\n    text-align: center;\n}\n\n._1vf4L {\n    margin-top: 22px;\n}\n\n.rUfjL {\n    display: -ms-flexbox;\n    display: flex;\n    -ms-flex-align: center;\n        align-items: center;\n}\n\n._2dRNN {\n    -ms-flex-pack: end;\n        justify-content: flex-end;\n}\n\n._3RVr8 {\n    font-size: 16px;\n    font-weight: 400;\n    margin: 0px;\n    padding-bottom: 18px;\n}\n\n._1ltp_ {\n    font-size: 16px;\n    font-weight: 400;\n    padding-left: 5px;\n    margin: 0px;\n}\n\n._1UP3c, ._1UP3c:hover, ._1UP3c:focus {\n   font-size: 16px;\n   font-weight: 500;\n   margin-right: 36px;\n}\n\n.t40Dv{\n    font-size: 16px;\n    margin-bottom: 22px;\n}\n\n._3nwEl{\n    min-height: 115px;\n}", ""]);

// exports
exports.locals = {
	"textAlignCenter": "_1xcpk",
	"marginTop": "_1vf4L",
	"displayFlex": "rUfjL",
	"justifyFlexEnd": "_2dRNN",
	"titleText": "_3RVr8",
	"labelFontSize": "_1ltp_",
	"linkText": "_1UP3c",
	"content": "t40Dv",
	"textAreaHeight": "_3nwEl"
};