exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n:root {\n  /*\n   * Typography\n   * ======================================================================== */\n\n  --font-family-base: 'Segoe UI', 'HelveticaNeue-Light', sans-serif;\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  --max-content-width: 1000px;\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */\n\n  --screen-xs-min: 480px;  /* Extra small screen / phone */\n  --screen-sm-min: 768px;  /* Small screen / tablet */\n  --screen-md-min: 992px;  /* Medium screen / desktop */\n  --screen-lg-min: 1200px; /* Large screen / wide desktop */\n\n  --btn-primary-bg: #414989;\n  --btn-bordercolor-bg: #000;\n  --btn-text-color: #fff;\n  --btn-primary-color: #ffffff;\n  --common-color: #414989;\n  --btn-secondary-bg: #ffffff;\n  --btn-secondary-color: #192865;\n  --rgb-color: #414989;\n  --btn-primary-bg-hover: #2C2C2C;\n  --common-text-color: #000000;\n  --darkmode-primary-color:#171D37;\n  --darkmode-secondary-color:#0E142F;\n  --darkmode-text-color:#C7D8EB;\n  --darkmode-link-color: #749EFF;\n  --darkmode-link-active-color: #0c0623;\n\n}\n/*\n\nCommon color code:\n\n--btn-bordercolor-bg\n--btn-bordercolor-bg\n--btn-primary-bg\n--common-color\n--btn-secondary-color\n--rgb-color\nfill:#000000;\n*/\n._1MvwH {\n\tpadding: 10px;\n}\n._3Z3jT {\n\tmargin: 0 auto;\n\tpadding: 0px;\n\tmax-width: 100%;\n}\n.MnlNH {\n\tpadding: 0px;\n}\n\n", ""]);

// exports
exports.locals = {
	"root": "_1MvwH",
	"container": "_3Z3jT",
	"logInModalBody": "MnlNH"
};