import { addPromoCode } from '../../../../actions/siteadmin/PromoCode/addPromoCode';
import { toastr } from 'react-redux-toastr';

async function submit(values, dispatch) {
    if (!values || !values.image) {
        toastr.error("Error Occured", "Please add image");
        return;
    }
    await dispatch(addPromoCode(values));
}

export default submit;