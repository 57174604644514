exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2U6kh{\n    text-align: center;\n}\n._2hAc5{\n    margin-top: 22px;\n}", ""]);

// exports
exports.locals = {
	"textAlignCenter": "_2U6kh",
	"marginTop": "_2hAc5"
};