import React from 'react';
import Layout from '../../../components/Layout/Layout';
import Page from '../../../components/Page/Page';
import Rider from './Rider';
import { getStaticContent } from '../../../actions/siteadmin/ContentPageAction';

async function action({ locale, store }) {
    const dataResult = await new Promise((resolve) => {
        require.ensure([], (require) => {
            try {
                resolve(require(`./rider.${locale}.md`));
            } catch (e) {
                resolve(require('./rider.md'));
            }
        }, 'rider');
    });

    await store.dispatch(getStaticContent(2));
    let getContentPage = store.getState().page;
    if (getContentPage && getContentPage.staticPageData) {
        return {
            title: getContentPage.staticPageData.metaTitle,
            description: getContentPage.staticPageData.metaDescription,
            component: <Layout><Rider data={getContentPage.staticPageData} /></Layout>,
        };
    } else {
        return {
            title: dataResult.title,
            component: <Layout><Page {...dataResult} /></Layout>,
        };
    }
}

export default action