import React, { Component } from 'react';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import { injectIntl, FormattedMessage } from 'react-intl';
import messages from '../../../../locale/messages';

import { connect } from 'react-redux';

import { FormGroup, FormControl } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './CancelBookingForm.css';
import bt from '../../../../components/commonStyle.css';

import Loader from '../../../Common/Loader/Loader';

import onSubmit from './submit';
import validate from './validate';

import getAllCancelReason from './getAllCancelReason.graphql';

class CancelBookingForm extends Component {

  componentDidMount() {
    const { change, userType, cancelData } = this.props;
    change('reason', 'other');
    change('requestBy', userType == 2 ? 'cancelledByDriver' : 'cancelledByRider');
    if (cancelData) {
      change('id', cancelData.id);
      change('currentPage', cancelData.currentPage);
    }
  }

  renderFormControlTextArea = ({ input, label, meta: { touched, error }, children, className }) => {
    const { formatMessage } = this.props.intl
    return (
      <div>
        <FormGroup className={s.formGroup}>
          <div className={'textAreaRTL'}>
            <FormControl
              {...input}
              className={className}
              placeholder={label}
              as="textarea"
              rows="3"
            >
              {children}
            </FormControl>
            {touched && error && <span className={bt.errorMessage}>{formatMessage(error)}</span>}
          </div>
        </FormGroup>
      </div>
    );
  }

  render() {
    const { handleSubmit, intl: { formatMessage }, loading, tripStatus, data: { getBookingCancel }, reason, userType, closeCancelBookingModal } = this.props;
    return (
      <div className={cx('maxwidthcenter', 'empty', 'customRatioButton')}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={s.content}>{userType == 1 ? formatMessage(messages.cancelRiderReason) : formatMessage(messages.cancelDriverReason)} </div>
          {
            getBookingCancel && getBookingCancel.result && getBookingCancel.result.map((item, index) => {
              return (
                <FormGroup className={s.formGroup} key={index}>
                  <div className={cx(s.blockRadioButton, s.displayFlex, s.radioText, 'textAlignRightRTL', bt.space3)}>
                    <Field name="reason" component="input" type="radio" value={item.reason} />
                    {' '} <label className={cx(bt.labelText, s.noMargin, 'radioTextLabelBookingRTL', s.labelFontSize)}>{item.reason}</label>
                  </div>
                </FormGroup>
              )
            })
          }
          <FormGroup className={s.formGroup}>
            <div className={cx(s.blockRadioButton, s.displayFlex, s.radioText, 'textAlignRightRTL', bt.space3)}>
              <Field name="reason" component="input" type="radio" value={"other"} />
              {' '} <label className={cx(bt.labelText, s.noMargin, 'radioTextLabelBookingRTL', s.labelFontSize)}>Others</label>
            </div>
          </FormGroup>
          {
            reason === 'other' && <Field name="otherReason" type="text" component={this.renderFormControlTextArea} className={s.textAreaHeight} label={formatMessage(messages.otherResons)} />
          }

          <div className={cx(s.marginTop, 'loaderRTL', s.displayFlex, s.justifyFlexEnd)}>
            <a href="#" className={cx(s.linkText, 'cancelBookingLinkRTL')} onClick={closeCancelBookingModal}><FormattedMessage {...messages.cancelButton} /></a>
            <Loader
              type={"button"}
              buttonType={"submit"}
              show={loading}
              className={cx(bt.btnPrimary)}
              label={formatMessage(messages.proceed)}
            />
          </div>
        </form>
      </div>
    );
  }
}

CancelBookingForm = reduxForm({
  form: "CancelBookingForm", // a unique name for this form
  validate,
  // destroyOnUnmount: false
})(CancelBookingForm);

const selector = formValueSelector('CancelBookingForm');
const mapState = (state) => ({
  tripStatus: selector(state, 'tripStatus') || {},
  loading: selector(state, 'loading'),
  reason: selector(state, 'reason'),
  otherReason: selector(state, 'otherReason'),
  cancelData: state.adminModalStatus.cancelData
});

const mapDispatch = {
  change
};

export default compose(
  injectIntl,
  withStyles(s, bt),
  connect(mapState, mapDispatch),
  graphql(getAllCancelReason, {
    options: (props) => ({
      variables: {
        userType: props.userType || 1
      },
      fetchPolicy: 'network-only',
      ssr: false
    })
  })
)(CancelBookingForm);
