import { updateMobileSettings } from '../../../actions/siteadmin/updateMobileSettings'
import { toastr } from 'react-redux-toastr';

async function submit(values, dispatch) {

    let isTripEnabled = values.distance === '0' && values.duration === '0' && values.estimatedPrice === '0' && values.pickupLocation === '0' && values.destinationLocation === '0';

    if (isTripEnabled) {
        toastr.error('Failed!', 'Please enable any one of the field in Trip Request Window');
        return;
    }

    if (values.isTripTimerToneEnable == '1' && !values.tripTimeTone) {
        toastr.error('Failed!', 'Please add trip timer tone');
        return;
    }

    await dispatch(
        updateMobileSettings(
            values.appForceUpdate,
            values.riderAndroidVersion,
            values.riderIosVersion,
            values.driverAndroidVersion,
            values.driverIosVersion,
            values.multipleStopsWaitingTime,
            values.preferredDistanceType,
            values.distance,
            values.duration,
            values.estimatedPrice,
            values.pickupLocation,
            values.destinationLocation,
            values.sleepDriverAndroid,
            values.sleepDriverios,
            values.contactPhoneNumber,
            values.contactEmail,
            values.skype,
            values.startRideRadius,
            values.tripTimeTone,
            values.isTripTimerToneEnable,
            values.openAppOnTrip,
            values.tripToneFile,
            values.maxWaitingTime,
            values.notificationInterval
        )
    )
}

export default submit;