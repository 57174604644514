import React from 'react';
import { graphql } from 'react-apollo';
import { flowRight as compose } from 'lodash';
import withStyles from 'isomorphic-style-loader/withStyles';
import s from './Driver.css';
import getAllHomePageSettings from './getAllHomePageSettings.graphql'
import Page from '../../../components/Page/Page';
import SignupGrid from '../../../components/Home/SignupGrid/SignupGrid';
import { HomeProvider } from '../../context/homeContext'
import { api, staticpageUploadDir } from '../../../config';
import FooterImageSection from '../../../components/Home/FooterImageSection/FooterImageSection';

class Driver extends React.Component {

  render() {
    const { getAllHomePageSettings: { loading, getAllHomePageSettings }, data } = this.props
    let homeSettings = {}
    if (!loading) {
      getAllHomePageSettings && getAllHomePageSettings.homePageData.map((item) => {
        homeSettings[item.name] = item.value
      })
    }

    return (
      <div>
        {!loading &&
          <div>
            <HomeProvider value={homeSettings}>
              <div className={s.homeBanner} />
              <Page
                html={data && data.content}
                title={data && data.metaTitle}
                pageBanner={data && data.pageBanner}
              />
              <SignupGrid className={s.noMargin}/>
              <FooterImageSection />
            </HomeProvider>
          </div>}
      </div>
    );
  }
}

export default compose(
  withStyles(s),
  graphql(getAllHomePageSettings, {
    name: 'getAllHomePageSettings',
    options: {
      ssr: true
    }
  })
)(Driver);
