import updateHomepageSettingsFooter from '../../../actions/siteadmin/updateHomepageSettingsFooter'

async function submit(values, dispatch) {
    if (!values.footerLogo1) {
        toastr.error('Error!', "Footer logo is required.");
        return;
    }
    await dispatch(updateHomepageSettingsFooter(values))
}

export default submit;